import React, { useEffect, useCallback, useMemo, useState } from "react";
import { makeStyles, Button, Snackbar } from "@material-ui/core";
import moment from 'moment'
import { Alert } from "@material-ui/lab";

moment.locale('it');

const TieSearchItem = (props) => {

    const styles = useStyles();

    const [isSiteError, setIsSiteError] = useState(false);

    return (
        <div className={styles.paperRoot}>
            <span className={styles.title}>Titolo</span>
            <div className={styles.rowItem}> {`${props?.data?.titolo || 'Non disponibile'} `}</div>

            <div className={styles.rowSpace} />

            <span className={styles.title}>Città</span>
            <div className={styles.rowItem}>{`${props?.data?.name || 'Non disponibile'} `}</div>

            <div className={styles.rowSpace} />

            <span className={styles.title}>Materia</span>
            <div className={styles.rowItem}>{`${props?.data?.materia || 'Non disponibile'} `}</div>

            <div className={styles.rowSpace} />

            <span className={styles.title}>Data iniziale</span>
            <div className={styles.rowItem}>{`${moment(props?.data?.dataIniziale).locale("it").format('DD/MM/YYYY') || 'Non disponibile'}`}</div>

            <div className={styles.rowSpace} />

            <span className={styles.title}>Data finale</span>
            <div className={styles.rowItem}>
                {
                    props?.data?.dataFinale ? (props?.data?.dataFinale.includes('1990') ? 'Non disponibile' : moment(props?.data?.dataFinale).locale("it").format('DD/MM/YYYY')) : 'Non disponibile'
                        `${props?.data?.dataFinale || 'Non disponibile'} `
                }
            </div>

            <div className={styles.rowSpace} />

            <span className={styles.title}>Stato di prenotabilità</span>
            <div className={styles.rowItem}>{`${props?.data?.stato || 'Non disponibile'} `}</div>

            <div className={styles.rowSpace} />

            <span className={styles.title}>Tipologia:</span>
            <div className={styles.rowItem}>{`${props?.data?.tipo || 'Non disponibile'} `}</div>

            <div className={styles.rowSpace} />

            <div className={styles.goToSiteContainer} >
                <Button
                    variant='contained'
                    classes={{ root: styles.btn }}
                    onClick={() => {
                        if (props?.data?.website) {
                            window.open(props.data.website);
                        } else {
                            setIsSiteError(true);
                        }
                    }}>
                    Vai al sito
                </Button>
            </div>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={isSiteError}
                onClose={() => { setIsSiteError(false) }}
            >
                <Alert onClose={() => { setIsSiteError(false) }} severity="error">
                    Impossibile accedere al sito!
                </Alert>
            </Snackbar>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    paperRoot: {
        backgroundColor: 'white',
        width: '100%',
        maxWidth: '600px',
        padding: '20px',
        borderRadius: '20px',
        //boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'

    },
    statusBar: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: '20px'
    },
    disableDot: {
        height: '25px',
        width: '25px',
        backgroundColor: '#bbb',
        borderRadius: '50%',
        display: 'inline-block',
    },
    prenotabileOnlineDot: {
        height: '25px',
        width: '25px',
        backgroundColor: '#80f765',
        borderRadius: '50%',
        display: 'inline-block',
    },
    nonPrenotabileOnlineDot: {
        height: '25px',
        width: '25px',
        backgroundColor: '#ffed30',
        borderRadius: '50%',
        display: 'inline-block',
    },
    goToSiteContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    rowSpace: {
        marginBottom: '20px',
    },
    rowItem: {
        color: '#7e7d7d',
    },
    title: {
        color: '#666565',
        fontWeight: 600,
        textTransform: 'upperreact '
    },
    btn: {
        fontFamily: 'Poppins', fontWeight: '500', fontSize: '20px', textTransform: 'unset', color: 'white',
        backgroundColor: '#0591b2', borderRadius: '15px'
    },
    statusTitle: {
        fontSize: '15px',
        marginLeft: '10px',
        color: '#666565',
        fontWeight: '600'
    }
}));

export default TieSearchItem;

/*
<div className={styles.statusBar}>
                <span className={dotStyle} />
                <span className={styles.statusTitle}>{props?.data?.stato || 'Non disponibile'}</span>
    </div>
*/

/*
   const dotStyle = useMemo(() => {
        if (props == undefined || props.data == undefined || props.data.stato == undefined) return styles.disableDot;

        if (props.data.stato.trim() == 'Evento prenotabile online') {
            return styles.prenotabileOnlineDot;
        } else if (props.data.stato.trim() == 'Evento non prenotabile online') {
            return styles.nonPrenotabileOnlineDot;
        } else {
            return styles.disableDot;
        }
    }, [props?.data?.stato])
*/
