import { makeStyles } from "@material-ui/core";
import React from "react";
import TieLogoCB from "./TieLogoCB";
import TieLogoDimensione from "./TieLogoDimensione";

const TieHeader = () => {

    const styles = useStyles();

    return (
        <div className={styles.headerRoot}>
            <div className={styles.logocb}>
                <TieLogoCB />
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    headerRoot: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        padding: '20px 20px 0px 20px',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    logoContainer: {
        width: '350px',
        [theme.breakpoints.down('sm')]: {
            width: '40%'
        }
    },
    logocb:{
        width: '100px',
        [theme.breakpoints.down('xs')]:{
            width: '50px'
        }
    }
}));

export default TieHeader;
