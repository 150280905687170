import { makeStyles } from "@material-ui/core";
import React, { useEffect, useCallback, useMemo, useState } from "react";

const TieResultSummary = (props) => {
    const styles = useStyles();
    return (
        <div className={styles.container}>
            <div className={styles.wrapperActive}>
                {
                    props?.quantity <= 0 && !props?.isLoading ?
                        <span className={styles.resultText}>Non ci sono risultati di ricerca</span>
                        :
                        <span className={styles.resultText}>{`Risultati di ricerca: ${props?.quantity || 0}`}</span>
                }
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: 'unset',
        //borderRadius: '20px',
        //boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        //padding:'20px'
    },
    resultText: {
        color: '#7e7d7d',
        fontWeight: '600',
    },
    divider: {
        marginBottom: '10px'
    },
    wrapperActive:{
        //backgroundColor: '#0591b2',
        backgroundColor: 'white',
        width: '100%',
        borderRadius: '15px',
        textAlign:'center',
        padding:'8px 14px',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
    }
}));

export default TieResultSummary;