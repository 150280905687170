import { makeStyles, useMediaQuery } from "@material-ui/core";
import React, { useEffect, useCallback, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useTheme } from '@material-ui/core/styles';

const TiePageLayout = (props) => {

    const [background, setBackground] = useState(null);

    const theme = useTheme();
    const mobileMatches = useMediaQuery(theme.breakpoints.down('sm')); //Boolean

    const useStyles = makeStyles(theme => ({
        pageLayout: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '0px 20px 0px 20px',
        },
    }));

    const styles = useStyles();

    return (
        <div className={styles.pageLayout}>
            <Helmet>
                <style>{
                    `body { 
                    background: url("nuvola 1.png") top right, url("Filigrana.png"), linear-gradient(180deg, #1ac4e9, #fff);
                    height: 100%;
                    margin: 0;
                    background-repeat: no-repeat, repeat, no-repeat;
                    background-attachment: fixed;
                    }`
                }</style>
            </Helmet>
            <props.component
                onRender={(background) => {
                    setBackground(background);
                }}
            />
        </div>
    );
}

export default TiePageLayout;


/*
logoDimensione: {
            position: 'fixed',
            top: 20,
            right: 30,
            zIndex: 999,
            display: 'flex',
            width: '400px',
            [theme.breakpoints.down('sm')]:{
                width: '30%'
            }
        },
*/

/*
<div className={styles.logoDimensione}>
    <TieLogoDimensione/>
</div>
*/