import { FormControl, FormControlLabel, FormLabel, makeStyles, Paper, Radio, RadioGroup } from "@material-ui/core";
import React, { useEffect, useCallback, useMemo, useState } from "react";
const TieFilterSearch = (props) => {

    const styles = useStyles();

    const [value, setValue] = React.useState('città');

    useEffect(() => {
        if (props?.onChange) { props?.onChange(value); }
    }, [])

    const handleChange = (event) => {
        setValue(event.target.value);
        if (props?.onChange) { props?.onChange(event.target.value); }
    };

    return (
        <Paper classes={{ root: styles.paperRoot }}>
            <FormControl classes={{ root: styles.formControlRoot }} component="fieldset">
                <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                    <div className={`${value == 'città' ? styles.formWrapperActive : styles.formWrapperDeactive}`}>
                        <FormControlLabel
                            classes={{ root: value == 'città' ? styles.formControlLabelActive : styles.formControlLabelInActive }}
                            value="città" control={<Radio/>}
                            label="Ricerca per città"
                        />
                    </div>
                    <div className={`${value == 'data' ? styles.formWrapperActive : styles.formWrapperDeactive}`}>
                        <FormControlLabel
                            classes={{ root: value == 'data' ? styles.formControlLabelActive : styles.formControlLabelInActive }}
                            value="data" control={<Radio/>}
                            label="Ricerca per date"
                        />
                    </div>
                    <div className={`${value == 'corso' ? styles.formWrapperActive : styles.formWrapperDeactive}`}>
                        <FormControlLabel
                            classes={{ root: value == 'corso' ? styles.formControlLabelActive : styles.formControlLabelInActive }}
                            value="corso" control={<Radio/>}
                            label="Ricerca per materia"
                        />
                    </div>
                </RadioGroup>
            </FormControl>
        </Paper>
    );
}

const useStyles = makeStyles(theme => ({
    paperRoot: {
        borderRadius: '20px',
        maxWidth: '600px',
        width: '100%',
        padding: '20px',
    },
    formWrapperActive: {
        backgroundColor: '#0591b2',
        width: '100%',
        borderRadius: '15px',
    },
    formWrapperDeactive: {
        backgroundColor: 'unset',
        width: '100%',
        borderRadius: '15px',
    },
    formControlLabelActive: {
        marginLeft: '0px',
        color:'white',
        '& .MuiButtonBase-root .MuiIconButton-label': {
            color: 'white'
        }
    },
    formControlLabelInActive: {
        marginLeft: '0px',
        '& .MuiButtonBase-root .MuiIconButton-label': {
            color: 'grey'
        }
    },
    formControlRoot: {
        width: '100%'
    },
}));

export default TieFilterSearch;
