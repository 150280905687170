import { makeStyles, useMediaQuery } from "@material-ui/core";
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import ScrollToTop from "react-scroll-up";
import TiePageLayout from "./components/TiePageLayout";
import Find from "./views/Find";
import PageNotFound from "./views/PageNotFound";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useTheme } from '@material-ui/core/styles';

function App() {

  const styles = useStyles();

  const theme = useTheme();
  const matchesDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Router>
      {/*<ScrollToTop showUnder={160}>
        <div className={styles.scrollContainer}>
          <ExpandLessIcon />
        </div>
  </ScrollToTop>*/}
      <Switch>
        <Route exact path="/"> <TiePageLayout component={Find} /> </Route>
        <Route path="*"> <TiePageLayout component={PageNotFound} /> </Route>
      </Switch>
    </Router>
  );
}

const useStyles = makeStyles(theme => ({
  scrollContainer: {
    height: '30px',
    width: '30px',
    borderRadius: '50%',
    backgroundColor: '#0591b2',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white'
  }
}));

export default App;