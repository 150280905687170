import { makeStyles, Button } from "@material-ui/core";
import React, { useEffect, useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router";
import TieLawyer404 from "../components/TieLawyer404";

const PageNotFound = () => {

    const styles = useStyles();
    let history = useHistory();

    return (
        <div className={styles.page} >

            <div className={styles.NotFoundContainer}>
                <h2 className={styles.oops}>Oops 404!</h2>
                <h2 className={styles.oops}>Non c'è nulla per te qui</h2>
                <div style={{ marginBottom: '20px' }}></div>
                <Button
                    variant='contained'
                    classes={{ root: styles.btn }}
                    onClick={() => {
                        history.replace('/');
                    }}>
                    Torna alla ricerca
                </Button>
            </div>

            <div className={styles.lawyerContainer}>
                <TieLawyer404 className={styles.lawyerImageRoot} />
            </div>

        </div>
    );
}

const useStyles = makeStyles(theme => ({
    page: {
        position: 'relative',
        height: 'calc(100vh - 100px)',
        width: '100%',
        maxWidth: '1920px',
        paddingTop: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxSizing: 'border-box'
    },
    oops: {
        margin: 0,
        color: 'white',
        textShadow: '0px 2px 1px -1px rgba(0,0,0,0.5),0px 1px 1px 0px rgba(0,0,0,0.6),0px 1px 3px 0px rgba(0,0,0,0.7)',
        textShadow: '2px 2px #305860',
        fontSize: '50px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '25px',
        }
    },
    "@keyframes fadeIn": {
        "0%": {
            opacity: 0,
            transform: "translateY(5rem)"
        },
        "100%": {
            opacity: 1,
            transform: "translateY(0)"
        }
    },
    "@keyframes lawyer": {
        "0%": { opacity: 0 },
        "100%": { opacity: 1 }
    },
    NotFoundContainer: {
        position: 'absolute',
        left: 0,
        top: '20%',
        animation: "$fadeIn .2s ease-in-out"
    },
    lawyerContainer: {
        //border: '1px solid black',
        position: 'absolute',
        bottom: 0,
        right: 0,
        height: '70%',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
        animation: '$lawyer 0.5s ease-in-out'
    },
    lawyerImageRoot: {
        height: '100%'
    },
    btn: {
        fontFamily: 'Poppins', fontWeight: '500', fontSize: '20px', textTransform: 'unset', color: 'white',
        backgroundColor: '#0591b2', borderRadius: '15px'
    }
}));

export default PageNotFound;