import { Box, makeStyles, Tab, Tabs } from "@material-ui/core";
import React, { useEffect, useCallback, useMemo, useState } from "react";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TieSearchItem from "./TieSearchItem";
import FeedbackIcon from '@material-ui/icons/Feedback';
import BlockIcon from '@material-ui/icons/Block';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import TieAlertMessage from "./TieAlertMessage";

/*
This component is used for separate courseData into tree view
-The first view is Prenotabili
-The second view is Non Prenotabili
-The third view is Non disponibili
*/

const TieSearchResultManager = ({ courseData }) => {

    const styles = useStyles();
    const theme = useTheme();
    const mobileDownSm = useMediaQuery(theme.breakpoints.down('sm'));

    const [activeTab, setActiveTab] = React.useState(0);

    const prenotabiliOnline = useMemo(() => courseData?.filter(e => e.stato.trim() == 'Evento prenotabile online'), [courseData])
    const prenotabiliInfoMessage = useMemo(() => 'I seguenti corsi sono prenotabili online!')

    const nonPrenotabiliOnline = useMemo(() => courseData?.filter(e => e.stato.trim() == 'Evento non prenotabile online'), [courseData])
    const nonPrenotabiliInfoMessage = useMemo(() => 'I seguenti corsi non sono prenotabili online.')
    
    const nonDisponibile = useMemo(() => courseData?.filter(e => e.stato.trim() != 'Evento prenotabile online' && e.stato.trim() != 'Evento non prenotabile online'), [courseData])
    const nonDisponibiliInfoMessage = useMemo(() => 'Per i seguenti corsi è scaduta l\'iscrizione oppure i posti sono esauriti. Se sei interessato ti invitiamo ugualmente a tentare.')

    const tabColor = useMemo(()=>{
        if(activeTab==0) return '#1c9501'
        else if(activeTab == 1) return '#dab800'
        else return '#B4440D'
    },[activeTab]);

    return (
        <div className={styles.paperRoot}>
            <div className={styles.tabsContainer}>
                <Tabs
                    fullWidth={true}
                    value={activeTab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={(event, newValue) => setActiveTab(newValue)}
                    aria-label="disabled tabs example"
                    TabIndicatorProps={{ style: { backgroundColor: tabColor } }}
                >
                    <Tab
                        classes={{ root: styles.tabRoot, selected: styles.selectedPrenotabili }}
                        icon={<CheckCircleOutlineIcon />}
                        label={!mobileDownSm ? `Prenotabili (${prenotabiliOnline?.length || '0'})` : `(${prenotabiliOnline?.length || '0'})`}
                    />
                    <Tab
                        classes={{ root: styles.tabRoot, selected: styles.selectedNonPrenotabili }}
                        icon={<ErrorOutlineIcon />}
                        label={!mobileDownSm ? `Non prenotabili (${nonPrenotabiliOnline?.length || '0'})` : `(${nonPrenotabiliOnline?.length || '0'})`}
                    />
                    <Tab
                        classes={{ root: styles.tabRoot, selected: styles.selectedNonDisponibili }}
                        icon={<BlockIcon />}
                        label={!mobileDownSm ? `Non disponibili (${nonDisponibile?.length || '0'})` : `(${nonDisponibile?.length || '0'})`}
                    />
                </Tabs>
            </div>

            <div className={styles.divider} ></div>
            <hr className={styles.dotted}></hr>

            {
                activeTab == 0 &&
                <div>
                    <TieAlertMessage visible={prenotabiliOnline.length > 0} message={prenotabiliInfoMessage} icon={FeedbackIcon} />
                    {
                        prenotabiliOnline?.map((course, index) => {
                            return (
                                <div key={course.idCourse} className={styles.courseItemContainer}>
                                    <TieSearchItem data={course} />
                                    {(index != prenotabiliOnline.length - 1) && <hr className={styles.dotted}></hr>}
                                </div>
                            )
                        })
                    }
                </div>
            }

            {
                activeTab == 1 &&
                <div>
                    <TieAlertMessage visible={nonPrenotabiliOnline.length > 0} message={nonPrenotabiliInfoMessage} icon={FeedbackIcon}/>
                    {
                        nonPrenotabiliOnline?.map((course, index) => {
                            return (
                                <div key={course.idCourse} className={styles.courseItemContainer}>
                                    <TieSearchItem data={course} />
                                    {(index != nonPrenotabiliOnline.length - 1) && <hr className={styles.dotted}></hr>}
                                </div>
                            )
                        })
                    }
                </div>
            }

            {
                activeTab == 2 &&
                <div>
                    <TieAlertMessage visible={nonDisponibile.length > 0} message={nonDisponibiliInfoMessage} icon={FeedbackIcon}/>
                    {
                        nonDisponibile?.map((course, index) => {
                            return (
                                <div key={course.idCourse} className={styles.courseItemContainer}>
                                    <TieSearchItem data={course} />
                                    {(index != nonDisponibile.length - 1) && <hr className={styles.dotted}></hr>}
                                </div>
                            )
                        })
                    }
                </div>
            }
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    divider: { margin: '20px 0px' },
    paperRoot: {
        backgroundColor: 'white',
        width: '100%',
        maxWidth: '600px',
        padding: '20px',
        borderRadius: '20px',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
    },
    courseItemContainer: {
        marginBottom: '20px',
        maxWidth: '600px',
        width: '100%',
        //animation: "$fadeIn .5s ease-in-out"
    },
    displayBlock: {
        display: 'none'
    },
    tabsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    dotted: {
        border: '0',
        marginLeft: '20px',
        marginRight: '20px',
        height: '2px',
        backgroundImage: 'linear-gradient(to right, transparent, #CCC, transparent)'
    },
    selectedPrenotabili:{
        color: '#1c9501 !important'
    },
    selectedNonPrenotabili:{
        color: '#dab800 !important'
    },
    selectedNonDisponibili:{
        color: '#B4440D !important'
    },
    tabRoot: {
        fontFamily: 'Poppins'
    },
    infoText: {
        fontFamily: 'Poppins',
        color: '#666565',
        marginLeft: '20px',
        marginRight: '20px',
        borderRadius: '5px',
        padding: '10px',
        //boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
        marginBottom: '10px'
    }
}));

export default TieSearchResultManager;