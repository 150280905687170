import { makeStyles, useMediaQuery } from "@material-ui/core";
import React, { useEffect, useCallback, useMemo, useState } from "react";
/*
This component is for show a general message
*/
const TieAlertMessage = (props) => {

    const styles = useStyles();

    return (
        <>
            {
                props?.visible &&
                <div className={styles.infoText} >
                    {
                        props?.icon &&
                        <props.icon classes={{ root: styles.icon }} />
                    }
                    <span>{props?.message || ''}</span>
                </div>
            }
        </>
    );
}
const useStyles = makeStyles(theme => ({
    infoText: {
        fontFamily: 'Poppins',
        color: '#666565',
        marginRight: '20px',
        borderRadius: '5px',
        padding: '20px',
        //boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
        marginBottom: '10px',
        display: 'flex',
        flexDirection: 'row'
    },
    icon: {
        transform:'translateY(3px)',
        marginRight: '5px'
    }
}));

export default TieAlertMessage;