import { makeStyles } from "@material-ui/core";
import React from "react";


const Tiefooter = (props) => {
    const styles = useStyles();
    return (
        <div className={styles.footer}>
            <p className={styles.credit}>Progetto realizzato con il contributo economico di Cassa Forense</p>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    footer:{
        position: 'fixed',
        zIndex: 0,
        bottom: 0,
        width: '100%',
        display: 'flex',
        backgroundColor:'#fff',
        paddingLeft: '1em'
    },
    credit:{
        //margin: '0px',
        textTransform: 'uppercase',
        fontWeight: '400'
    }
}));


export default Tiefooter;