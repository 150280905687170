import React, { useEffect, useCallback, useMemo, useState } from "react";
import { CircularProgress, makeStyles, Snackbar } from "@material-ui/core";
import TieSearchField from "../components/TieSearchField";
import TieFilterSearch from "../components/TieFilterSearch";
import TieSearchItem from "../components/TieSearchItem";
import TieFindMe from "../components/TieFindMe";
import { Alert } from "@material-ui/lab";
import APIConfig from '../config/APIConfiguration.json'
import TieLawyerImage from "../components/TieLawyerImage";
import TieResultSummary from "../components/TieResultSummary";
import TieSearchResultManager from "../components/TieSearchResultManager";
import axios from "axios";


const Find = (props) => {

    const styles = useStyles();

    //Library for execute fetch
    const axios = require('axios');

    //This variable is used for check if research is by cap, city o course
    const [filterValue, setFilterValue] = useState();

    //This array contains all fetched courses caused by user's searching operation
    const [courseData, setCourseData] = useState([]);
    const [isLoadingCourseError, setIsLoadingCourseError] = useState(false);
    const [isLoadingCourse, setIsLoadingCourse] = useState(false);


    //This function is for fetch course by City research
    const getCourseByCityAsync = useCallback(async (data) => {
        setIsLoadingCourse(true);
        setCourseData([]);
        axios.get(`${APIConfig[APIConfig.ENV].baseUrl}/getCourse/order?idOrdine=${data?.idOrdine}`)
            .then((response) => {
                setCourseData(response?.data?.data);
                setIsLoadingCourse(false);
            }, (error) => {
                setIsLoadingCourseError(true);
                setIsLoadingCourse(false);
            });
    }, []);

    const getCourseByCapAsync = useCallback(async (data) => {
        setIsLoadingCourse(true);
        setCourseData([]);
        axios.get(`${APIConfig[APIConfig.ENV].baseUrl}/getCourse/cap?cap=${data.cap}`)
            .then((response) => {
                setCourseData(response?.data?.data);
                setIsLoadingCourse(false);
            }, (error) => {
                setIsLoadingCourseError(true);
                setIsLoadingCourse(false);
            });
    }, []);

    const getCourseByDateInterval = useCallback(async (data) => {
        setIsLoadingCourse(true);
        setCourseData([]);
        axios.get(`${APIConfig[APIConfig.ENV].baseUrl}/getCourse/date?start=${data?.start}&end=${data?.end}`)
            .then((response) => {
                setCourseData(response?.data?.data);
                setIsLoadingCourse(false);
            }, (error) => {
                setIsLoadingCourseError(true);
                setIsLoadingCourse(false);
            });
    }, []);

    const getCourseByCourseName = useCallback((data) => {
        setIsLoadingCourse(true);
        setCourseData([]);
        axios.post(`${APIConfig[APIConfig.ENV].baseUrl}/getCourse/courseName`, { courseName: data })
            .then((response) => {
                setCourseData(response?.data?.data);
                setIsLoadingCourse(false);
            }, (error) => {
                setIsLoadingCourseError(true);
                setIsLoadingCourse(false);
            });
    }, [setCourseData])

    return (
        <div className={styles.page} >
            <div className={styles.findMeContainer}>
                <TieFindMe className={styles.findMeLogo} />
            </div>
            <span className={styles.eventiFormativi}>TROVA IL TUO EVENTO FORMATIVO</span>
            <div className={styles.divider} />
            <div className={styles.descriptionArea}>
                <span>{`Tutti gli eventi formativi a tua disposizione. Scrivi l'evento formativo, le date oppure la città per visualizzare i risultati.`}</span>
            </div>
            <div className={styles.divider} />
            <div className={styles.searchFieldContainer}>
                <TieSearchField
                    type={filterValue}
                    onCourseSearch={async (data) => data ? getCourseByCourseName(data) : setCourseData([])}
                    onDataSearch={async (data) => data ? getCourseByDateInterval(data) : setCourseData([])}
                    onCitySearch={async (data) => data ? getCourseByCityAsync(data) : setCourseData([])}
                />
            </div>


            <div className={styles.divider} />
            <TieFilterSearch onChange={(value) => setFilterValue(value)} />
            <div className={styles.divider} />

            <TieResultSummary quantity={courseData?.length || 0} isLoading={isLoadingCourse} />

            <div className={styles.divider} />

            {
                isLoadingCourse &&
                <div className={styles.loadingContainer}>
                    <CircularProgress style={{ 'color': 'blue' }} />
                </div>
            }

            <TieSearchResultManager courseData = {courseData}/>

            <div className={styles.spacer} />

            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={isLoadingCourseError}
                onClose={() => { setIsLoadingCourseError(false) }}
            >
                <Alert onClose={() => { setIsLoadingCourseError(false) }} severity="error">
                    Impossibile risolvere i criteri di ricerca. Riprovare più tardi!
                </Alert>
            </Snackbar>

            <div className={styles.lawyer}>
                <TieLawyerImage className={styles.lawyerImageRoot} />
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    "@keyframes fadeIn": {
        "0%": {
            opacity: 0,
            transform: "scale(0.1)"
        },
        "60%": {
            opacity: 1,
            transform: "scale(1.1)"
        },
        "100%": {
            opacity: 1,
            transform: "scale(1)"
        }
    },
    "@keyframes noResult": {
        "0%": {
            opacity: 0,
            transform: "translateX(200px)"
        },
        "100%": {
            opacity: 1,
            transform: "translateX(0px)"
        }
    },
    page: {
        //border: '1px solid black',
        width: '100%',
        maxWidth: '1920px',
        paddingTop: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    findMeContainer: {
        //border: '1px solid black',
        maxWidth: '600px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    findMeLogo: {
        width: '100%',
    },
    eventiFormativi: {
        margin: 0,
        color: 'white',
        fontSize: '50px',
        fontWeight: '900',
        [theme.breakpoints.down('sm')]: {
            fontSize: '25px',
        }
    },
    divider: {
        marginBottom: '20px'
    },
    searchFieldContainer: {
        width: '100%',
        maxWidth: '600px',
    },
    resultSummaryContainer: {
        width: '100%',
        maxWidth: '600px',
    },
    courseItemContainer: {
        marginBottom: '20px',
        maxWidth: '600px',
        width: '100%',
        animation: "$fadeIn .5s ease-in-out"
    },
    loadingContainer: {
        marginBottom: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    resultText: {
        color: 'white',
        fontWeight: '600',
        textShadow: '1px 1px #305860',
    },
    spacer: {
        height: '100px'
    },
    lawyer: {
        position: 'fixed',
        right: '0',
        bottom: '-10px',
        zIndex: -999,
        height: '70%',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    lawyerImageRoot: {
        height: '100%'
    },
    noResultBanner: {
        color: 'white',
        fontWeight: '600',
        textShadow: '1px 1px #305860',
        animation: "$fadeIn .5s ease-in-out",
    },
    dimensioneLogoContainer: {
        //border: '2px solid red',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px 20%'
    },
    descriptionArea: {
        maxWidth: '600px',
        width: '100%',
        color: 'white',
        textAlign: 'center'
    }
}));

export default Find;

/* OLD
<span className={styles.resultText}>{`RISULTATI DI RICERCA: ${courseData.length || 0}`}</span>
            <div className={styles.divider} />
            {
                courseData.length <= 0 && !isLoadingCourse &&
                <div className={styles.noResultBanner}>
                    <p>NON CI SONO RISULTATI DI RICERCA</p>
                </div>
            } */
