import {TextField, makeStyles, Snackbar, Button} from "@material-ui/core";
import React, {useEffect, useCallback, useMemo, useState} from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {Alert} from "@material-ui/lab";
import {CircularProgress} from '@material-ui/core';
import APIConfig from '../config/APIConfiguration.json'


const TieSearchField = (props) => {

    const styles = useStyles();

    //Library for execute fetch
    const axios = require('axios');

    const [ordiniData, setOrdiniData] = useState([]);
    const [ordineValue, setOrdineValue] = useState('');

    const [capsData, setCapsData] = useState([]);
    const [capValue, setCapValue] = React.useState('');

    const [freeSearchValue, setFreeSearchValue] = useState('');

    const [isCityLoadingError, setIsCityLoadingError] = useState(false);
    const [isCityLoading, setIsCityLoading] = useState(false);
    const [isCapLoadingError, setIsCapLoadingError] = useState(false);
    const [isCapLoading, setIsCapLoading] = useState(false);

    useEffect(() => {
        getAllOrdersAsynch();
        //getAllCapsAsynch(); //Precedente chiamata per ottenere tutti i caps (cosa che non serve piu)
    }, [])

    //This fetch obtain all orders used for autocomplete (type = corso)
    const getAllOrdersAsynch = useCallback(async () => {
        setIsCityLoading(true);
        axios.get(`${APIConfig[APIConfig.ENV].baseUrl}/convenzionati/ordini`)
            .then((response) => {
                setOrdiniData(response.data.data);
                setIsCityLoading(false);
            }, (error) => {
                setIsCityLoadingError(true);
                setIsCityLoading(false);
            });

    }, []);

    //This fetch obtain all caps
    const getAllCapsAsynch = useCallback(async () => {
        setIsCapLoading(true);
        axios.get(`${APIConfig[APIConfig.ENV].baseUrl}/getCaps`)
            .then((response) => {
                setCapsData([{cap: "86100", name: "Campobasso"}, ...response.data.data]);
                setIsCapLoading(false);
            }, (error) => {
                setIsCapLoadingError(true);
                setIsCapLoading(false);
            });
    }, []);

    const [startDate, setStartDate] = useState("2022-05-01");
    const [endDate, setEndDate] = useState("2022-06-01");

    return (
        <div>
            {
                (isCityLoadingError || isCapLoading) &&
                <div className={styles.loadingContainer}>
                    <CircularProgress/>
                </div>
            }
            {
                props?.type == 'corso' &&
                <TextField
                    classes={{root: styles.freeTextFieldRoot}}
                    onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                            if (props?.onCourseSearch && freeSearchValue) props.onCourseSearch(freeSearchValue);
                            event.preventDefault();
                        }
                    }}
                    fullWidth
                    size='medium'
                    inputProps={{maxLength: 40}}
                    InputProps={{
                        classes: {input: styles.freeTextFieldInputProps},
                        disableUnderline: true,
                        endAdornment: (
                            <div
                                className={`${freeSearchValue ? styles.freeEndAdornmentActive : styles.freeEndAdornmentInactive}`}
                                onClick={() => {
                                    if (props?.onCourseSearch && freeSearchValue) props.onCourseSearch(freeSearchValue);
                                }}
                            >
                                FINDME!
                            </div>
                        )
                    }}
                    placeholder='Cerca una materia...'
                    id="outlined-multiline-flexible"
                    value={freeSearchValue}
                    onChange={(event) => setFreeSearchValue(event.target.value)}
                />
            }
            {
                props?.type == 'data' &&
                <form className={styles.container} noValidate>
                    <TextField
                        inputProps={{className: styles.input}}
                        id="date"
                        label="Data iniziale del corso"
                        type="date"
                        defaultValue={startDate}
                        className={styles.textField}
                        InputLabelProps={{
                            shrink: true,
                            className: styles.txtLabel,
                        }}
                        onChange={(evt) => {
                            evt.preventDefault();
                            setStartDate(evt?.target?.value);
                        }}
                    />
                    <TextField
                        inputProps={{className: styles.input}}
                        id="date"
                        label="Data finale del corso"
                        type="date"
                        defaultValue={endDate}
                        className={styles.textField}
                        InputLabelProps={{
                            shrink: true,
                            className: styles.txtLabel,
                        }}
                        onChange={(evt) => {
                            evt.preventDefault();
                            setEndDate(evt?.target?.value);
                        }}
                    />
                    <Button
                        className={styles.btn}
                        onClick={() => {
                            if (props?.onDataSearch) props.onDataSearch({start: startDate, end: endDate});
                        }}
                    >
                        Cerca
                    </Button>
                </form>
            }
            {/*<Autocomplete
                size='small'
                classes={{root: styles.capAutocompleteRoot}}
                id="combo-box-demo"
                options={capsData || []}
                getOptionLabel={(option) => `${option?.cap} - ${option?.name}`}
                onChange={(event, newValue) => {
                setCapValue(newValue);
                if (props?.onDataSearch) props.onDataSearch(newValue);
            }}
                renderInput={(params) => <TextField {...params} placeholder="Seleziona un CAP" variant="outlined" />}
                />*/}
            {
                props?.type == 'città' &&
                <Autocomplete
                    size='small'
                    classes={{root: styles.capAutocompleteRoot}}
                    id="combo-box-demo"
                    options={ordiniData || []}
                    getOptionLabel={(option) => option?.name}
                    onChange={(event, newValue) => {
                        setOrdineValue(newValue);
                        if (props?.onCitySearch) props.onCitySearch(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} placeholder="Seleziona una città"
                                                        variant="outlined"/>}
                />
            }
            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                open={isCityLoadingError}
                onClose={() => {
                    setIsCityLoadingError(false)
                }}
            >
                <Alert onClose={() => {
                    setIsCityLoadingError(false)
                }} severity="error">
                    Impossibile caricare la lista delle città
                </Alert>
            </Snackbar>

            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                open={isCapLoadingError}
                onClose={() => {
                    setIsCapLoadingError(false)
                }}
            >
                <Alert onClose={() => {
                    setIsCapLoadingError(false)
                }} severity="error">
                    Impossibile caricare la lista dei CAPs
                </Alert>
            </Snackbar>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    freeTextFieldRoot: {
        backgroundColor: 'white',
        borderRadius: '20px',
    },
    freeTextFieldInputProps: {
        paddingLeft: '10px'
    },
    freeEndAdornmentActive: {
        width: '100%',
        maxWidth: '100px',
        height: '39px',
        borderTopRightRadius: '20px',
        borderBottomRightRadius: '20px',
        backgroundColor: "#0591b2",
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
    },
    freeEndAdornmentInactive: {
        width: '100%',
        maxWidth: '100px',
        height: '39px',
        borderTopRightRadius: '20px',
        borderBottomRightRadius: '20px',
        backgroundColor: "grey",
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white'
    },

    capAutocompleteRoot: {
        width: '100% !important',
        maxWidth: '600px',
        backgroundColor: 'white',
        borderRadius: '20px',
        '&.MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root': {
            borderRadius: '20px !important'
        }
    },
    loadingContainer: {
        marginBottom: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    container: {
        display: 'flex',
        width: "100%",
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            gap: "20px"
        }
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
        '& .MuiInput-underline:before': {borderBottomColor: '#0591b2'},
        '& .MuiInput-underline:after': {borderBottomColor: '#0591b2'},
        [theme.breakpoints.down("sm")]: {
            width: "unset"
        }
    },
    dateContainer: {
        display: "flex",
        flexDirection: "row",
        justifyItems: "space-between",
        border: "1px solid black"
    },
    btn: {
        fontFamily: 'Poppins', fontWeight: '500', fontSize: '20px', textTransform: 'unset', color: 'white',
        backgroundColor: '#0591b2', borderRadius: '15px'
    },
    input: {
        color: "white",
        fontFamily: "Poppins",
        fontWeight: "bolder"
    },
    txtLabel:{
        fontFamily: 'Poppins', fontWeight: '500'
    }
}));

export default TieSearchField;
